section.goto
  margin-bottom: 10%
  @media (min-width: 1024px)
    margin-bottom: 6%
  @media (min-width: 1280px)
    margin-bottom: 5%
  .container
    align-items: center
    gap: 2rem 0
    @media (min-width: 768px)
      width: 75%
    @media (min-width: 1440px)
      width: 70%
      gap: 2.5rem 0
    @media (min-width: 1600px)
      width: 65%
      gap: 3rem 0
    @media (min-width: 1900px)
      width: 60%
    .coma
      border: 1px solid $mainColor
      border-radius: 20px
      padding: 2rem 2rem 2rem 3rem
      width: 100%
      p
        font-size: 24px
        font-weight: 200
        color: $mainColor
        text-align: left
      .mark
        width: 25px
        top: 2.2rem
        left: 1rem
      @media (min-width: 768px)
        padding: 2rem 2rem 2rem 4rem
        .mark
          width: 30px
          left: 1.2rem
        p
          font-size: 26px
      @media (min-width: 1280px)
        p
          font-size: 30px
      @media (min-width: 1440px)
        p
          font-size: 34px
      @media (min-width: 1900px)
        p
          font-size: 40px
    .advance
      align-items: flex-start
      width: 100%
      .contact_click
        &:hover
          cursor: pointer
      h2
        font-size: 24px
        margin-bottom: 5px
        span
          font-size: 22px
          color: $mainColor
      .text
        width: 100%
        align-items: flex-start
        a
          color: $white
          padding: 8px 15px
          border-radius: 20px
          background: $mainColor
          cursor: pointer
          margin-top: 10px
          @media (min-width: 1280px)
            transition: .3s
            &:hover
              transform: scale(1.05)
        @media (min-width: 768px)   
          flex-direction: row
          justify-content: space-between
          gap: 0 20%
    .lists
      width: 100%
      gap: 2rem 0
      @media (min-width: 1280px)
        flex-direction: row
        justify-content: space-between
        gap: 0 4%
      .item
        width: 100%
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15)
        border-radius: 25px
        .title
          border-radius: 25px
          background: $mainColor
          align-items: center
          padding: 1.5rem
          gap: 0 2rem
          p
            color: $white
            text-align: left
            span
              font-weight: 700
          img
            width: 40px
          @media (min-width: 768px)
            img
              width: 50px
            p, span
              font-size: 16px
        .list
          padding: 1.5rem
          gap: 1rem 0
          &>.flex
            border: 1px solid #c4c4c4
            border-radius: 20px
            padding: 1rem
            align-items: center
            gap: 0 1rem
            img
              width: 30px
            .text
              align-items: flex-start
              h4
                font-size: 14px
                @media (min-width: 1366px)
                  font-size: 16px
              p
                text-align: left
              h4 span
                font-weight: 400
            @media (min-width: 1280px)
              height: 100px
              gap: 0 1.5rem
              padding: 0 1.5rem
              img
                width: 35px
            