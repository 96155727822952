.thanks
    width: 100%
    padding: 18% 0%
    text-align: center
    img
        width: 350px
    h1
        font-size: 16px
        font-weight: 700
        color: $mainColor
        span
            font-size: 16px
            color: #000
            font-weight: 700