section.form
  margin-bottom: 10%
  @media (min-width: 1024px)
    margin-bottom: 8%
  @media (min-width: 1280px)
    margin-bottom: 5%
  .container
    border-radius: 20px
    padding: 2rem
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15)
    h3
      color: $mainColor
      font-size: 22px
      margin-bottom: .5rem
      span
        color: $textColor
        font-size: 22px
        font-weight: 400
      .blackSpan
        font-weight: 700
        text-decoration: line-through
    &>p
      margin-bottom: 5px
      span
        font-weight: 700
    @media (min-width: 768px)
      width: 80%
      padding: 4rem
    @media (min-width: 1280px)
      width: 65%
      padding: 5rem
      h3
        font-size: 24px
        width: 70%
        span
          font-size: 24px
    @media (min-width: 1440px)
      width: 55%
      padding: 4rem 6rem
      h3
        width: 85%
    @media (min-width: 1900px)
      width: 50%
      h3
        font-size: 28px
    form
      gap: 1rem 0
      input, select, textarea
        width: 100%
        border: 1px solid #c4c4c4
        border-radius: 15px
        background: $white
      input, select
        height: 2.5rem
        padding: 0 10px
      textarea
        resize: none
        padding: 5px 10px
      @media (min-width: 768px)
        flex-direction: row
        flex-wrap: wrap
        justify-content: space-between
        input
          width: 47%
      .policy
        width: 100%
        align-items: center
        gap: 1.3rem 0
        p
          text-align: left
          font-size: 12px
          a
            font-size: 12px
            font-weight: 700
            color: $textColor
        @media (min-width: 768px)
          flex-direction: row
          justify-content: space-between
          align-items: flex-start
          gap: 0 10%
        @media (min-width: 1280px)
          p a
            &:hover
              text-decoration: underline
        .btnFormContacto
          color: $white
          padding: 8px 15px
          border-radius: 20px
          background: $mainColor
          cursor: pointer
          @media (min-width: 1280px)
            transition: .3s
            &:hover
              transform: scale(1.05)
      &>span
        font-size: 10px
    .btn
      background: $mainColor
      gap: 0 10px
      align-items: center
      justify-content: center
      padding: 8px 20px
      border-radius: 20px
      border: 1px solid $mainColor
      color: #fff
      width: 200px
      height: 55px
      height: 55px
      margin-top: 5%
      span
        color: #fff
      &:hover
        cursor: pointer
        color: $textColor
        background: #fff
        border: 1px solid $mainColor
        span
          color: $mainColor