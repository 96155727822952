@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap')
@import ../css/slick.css

@import components/variables
@import components/header
@import components/base
@import components/header
@import components/footer
@import components/float
@import home/banner
@import home/proved
@import home/video
@import home/form
@import home/discover
@import home/goto
@import home/offer
@import home/academy

@import thanks/content

.grecaptcha-badge
    visibility: hidden