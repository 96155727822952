section.bannerHome
  width: 100%
  margin-bottom: 3rem
  @media (min-width: 1280px)
    margin-bottom: 8%
  @media (min-width: 1440px)
    margin-bottom: 6%
  .content
    width: 100%
  .content a
    display: flex!important