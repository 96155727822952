header
  position: fixed
  width: 100%
  top: 0
  background: $white
  z-index: 2
  .container
    padding: 1.2rem 0
    justify-content: space-between
    align-items: center
    .logo
      width: 150px
      @media (min-width: 1024px)
        width: 200px
      img
        width: 100%
    .contact
      background: $mainColor
      border-radius: 20px
      padding: 8px 15px
      color: $white
      font-size: 12px
      @media (min-width: 1024px)
        font-size: 14px
      @media (min-width: 1600px)
        font-size: 16px
    .contact_click
      &:hover
        cursor: pointer
