section.offer
  background: #F9FAFC
  padding: 2rem 0
  margin-bottom: 10%
  @media (min-width: 1024px)
    margin-bottom: 6%
  @media (min-width: 1280px)
    margin-bottom: 5%
  @media (min-width: 1600px)
    padding: 4rem 0
  .contact_click
    &:hover
      cursor: pointer
  .container
    align-items: center
    &>img
      display: none
    @media (min-width: 1024px)
      flex-direction: row
      gap: 0 5%
      &>img
        display: block
        width: 30%
    @media (min-width: 1280px)
      &>img
        width: 35%
    @media (min-width: 1440px)
      width: 80%
    @media (min-width: 1600px)
      width: 75%
    @media (min-width: 1900px)
      width: 65%
    .items
      align-items: center
      gap: 2rem 0
      @media (min-width: 768px)
        flex-direction: row-reverse
        align-items: stretch
        gap: 0 5%
    .items .pt1
      width: 100%
      align-items: flex-start
      justify-content: space-between
      background: $mainColor
      border-radius: 25px
      padding: 2rem
      @media (min-width: 1280px)
        padding: 2rem 3rem
      .text
        h1, h2, h3, p
          color: $white
        h1
          font-size: 28px
        h2
          font-size: 20px
        h3
          font-size: 16px
          font-weight: 400
        h3:last-of-type  
          text-decoration: line-through
        p
          margin: 1.5rem 0
      a
        background: $white
        gap: 0 10px
        align-items: center
        justify-content: center
        padding: 8px 20px
        border-radius: 20px
        span
          color: $textColor
    .items .pt2
      width: 100%
      align-items: flex-start
      background: $white
      border-radius: 25px
      padding: 2rem
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15)
      @media (min-width: 1280px)
        padding: 2rem 3rem
      h1
        color: $mainColor
        font-size: 28px
      ul
        padding-left: 25px
        margin: 1rem 0
        gap: 1rem 0
        li
          color: $textColor
          padding-left: 5px