*
  margin: 0
  padding: 0
  border: none
  outline: none
  box-sizing: border-box

html
  scroll-behavior: smooth

body
  max-width: 2000px
  margin: auto
  caret-color: $mainColor
  font-family: 'Poppins', sans-serif

img
  max-width: 100%
  height: auto
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -o-user-select: none
  user-select: none
  -webkit-user-drag: none
  -khtml-user-drag: none
  -moz-user-drag: none
  -o-user-drag: none

a, p, span, li, label
  font-size: 14px
  @media (min-width: 1366px)
    font-size: 16px

p
  text-align: justify
  color: $textColor
  @media (min-width: 1900px)
    font-size: 18px

input textarea
  font-size: 16px
  font-family: 'Poppins', sans-serif
  
input textarea, select
  color: $textColor


a
  text-decoration: none
  &:active
    -webkit-tap-highlight-color: transparent

.dnone
  display: none

.flex
  display: flex

.colflex
  display: flex
  flex-direction: column

.grid
  display: grid

.container
  width: 85%
  margin: auto

.relative
  position: relative

.absolute
  position: absolute