footer
  background: $mainColor
  .container
    align-items: center
    gap: 3rem 0
    padding: 3rem 0
    border-bottom: 1px solid $white
    .logoFoo
      width: 60%
      @media (min-width: 1024px)
        display: none
      @media (min-width: 1366px)
        display: block
    .item
      align-items: center
      gap: 5px 0
      &>img
        width: 20px
      h3, p
        color: $white
        text-align: center
      h3
        text-transform: uppercase
        letter-spacing: 3px
      .social
        gap: 0 1rem
        align-items: flex-end
    @media (min-width: 768px)
      width: 40%
    @media (min-width: 1024px)
      width: 85%
      flex-direction: row
      align-items: flex-start
      justify-content: space-between
      gap: 0 5%
      .logoFoo
        width: 10%
      .item
        align-items: flex-start
        position: relative
        h3, p
          text-align: left
        &>img
          position: absolute
          top: -2rem
          left: 0
    @media (min-width: 1280px)
      padding: 4rem 0 2rem
      // align-items: center
      .logoFoo
        width: 16%
        align-self: center
    @media (min-width: 1440px)
      gap: 0
      padding: 5rem 0 3rem
  .gtnd
    width: 100%
    padding: 10px 0
    align-items: center
    p
      color: $white
      font-size: 12px
    .marker
      display: none
    @media (min-width: 768px)
      flex-direction: row
      align-items: center
      justify-content: center
      gap: 0 6px
      .marker
        display: inline-flex