section.discover
  margin-bottom: 10%
  @media (min-width: 1024px)
    margin-bottom: 8%
  @media (min-width: 1280px)
    margin-bottom: 5%
  .container
    align-items: center
    gap: 1rem 0
    @media (min-width: 768px)
      flex-direction: row
      justify-content: space-between
      gap: 0
      img
        width: 50%
    @media (min-width: 1024px)
      width: 75%
    @media (min-width: 1280px)
      width: 70%
    @media (min-width: 1900px)
      width: 60%
  .text
    align-items: flex-start
    h1
      color: $mainColor
      font-size: 26px
    h2
      font-size: 22px
      color: $white
      background: $mainColor
      border-radius: 10px
      padding: 2px 8px
      margin-bottom: 1rem
    p:first-of-type
      margin-bottom: 1rem
    p span
      font-weight: 700
    @media (min-width: 768px)
      width: 40%
      h1, h2
        font-size: 26px
    @media (min-width: 1440px)
      h1, h2
        font-size: 30px
    @media (min-width: 1900px)
      h1, h2
        font-size: 34px
