section.academy
  margin-bottom: 10%
  @media (min-width: 1024px)
    margin-bottom: 6%
  @media (min-width: 1280px)
    margin-bottom: 5%
  .contact_click
    &:hover
      cursor: pointer
  .container
    align-items: center
    @media (min-width: 768px)
      width: 80%
    @media (min-width: 1280px)
      width: 70%
    @media (min-width: 1440px)
      width: 65%
    @media (min-width: 1900px)
      width: 60%
    .video
      width: 100%
      margin: 1rem 0
      @media (min-width: 768px)
        width: 85%
      @media (min-width: 1440px)
        width: 75%
      video,iframe
        width: 100%
        border-radius: 15px
        aspect-ratio: 16/9
    h1, h2
      color: $mainColor
      text-align: center
    h1
      font-size: 28px
    h2
      font-size: 22px
      margin-bottom: 10px
      @media (min-width: 768px)
        width: 80%
      @media (min-width: 1440px)
        width: 55%
      @media (min-width: 1900px)
        width: 45%
    &>p
      margin-bottom: 2rem
      span
        font-weight: 700
      @media (min-width: 768px)
        width: 70%
      @media (min-width: 1440px)
        width: 55%
      @media (min-width: 1900px)
        width: 50%
    a:last-of-type
      color: $white
      padding: 8px 15px
      border-radius: 20px
      background: $mainColor
      cursor: pointer
      margin-top: 2rem
      @media (min-width: 1280px)
        transition: .3s
        &:hover
          transform: scale(1.05)
  .container .faqs
    width: 100%
    gap: 3rem 0
    .option
      width: 100%
      align-items: center
      gap: 1rem 0
      .title
        width: 100%
        justify-content: space-between
        align-items: center
        gap: 0 1rem
        h3
          flex: 1
          color: $mainColor
          text-align: center
          text-wrap: nowrap
          font-size: 22px
        &::before, &::after
          content: ''
          border-top: 1px solid $mainColor
          width: 100%
      .div
        width: 100%
        .accTittle,.accContent
          margin: 0 auto
      .accTittle
        width: 100%
        border-bottom: 1px solid #c4c4c4
        justify-content: space-between
        align-items: flex-start
        padding: 0 0 1rem 0
        gap: 0 2rem
        // @media (min-width: 1024px)
        //   padding: .8rem 0
        @media (min-width: 1280px)
          cursor: pointer
          width: 85%
        @media (min-width: 1440px)
          width: 80%
        @media (min-width: 1900px)
          width: 70%
        .content
          align-items: flex-start  
          h4
            padding-left: 1rem
            color: $textColor
            @media (min-width: 1600px)
            font-size: 20px
          p
            font-weight: 700
            text-align: left
            padding-left: 1rem
        span
          color: $textColor
          font-size: 25px
        .minus
          display: none
      .rotated
        border: none
        padding: 0
        .minus
          display: block
        .add
          display: none
      .accContent
        display: none
        width: 100%
        @media (min-width: 1280px)
          width: 85%
        @media (min-width: 1440px)
          width: 80%
        @media (min-width: 1900px)
          width: 70%
        p , ul
          color: $textColor
          text-align: left
        p
          text-align: left
          font-weight: 700
          white-space: pre-wrap 
          overflow-wrap: break-word
          @media (min-width: 1600px)
            font-size: 16px
        ul
          padding-left: 20px
          li
            font-weight: 700
            margin-bottom: 5px
      .openedContent
        display: block
        padding: 5px 1rem 1rem 1rem
        // transition: .3s opacity ease
        border-bottom: 1px solid #c4c4c4
        // @media (min-width: 1024px)
        //   padding: 2rem 3rem