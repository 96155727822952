section.proved
  margin-bottom: 10%
  @media (min-width: 1024px)
    margin-bottom: 6%
  @media (min-width: 1280px)
    margin-bottom: 5%
  .container
    gap: 1.5rem 0
    @media (min-width: 768px)
      gap: 0
      flex-direction: row
      align-items: center
      justify-content: space-between
      img:first-child
        width: 36%
      img:last-child
        width: 50%
        border-radius: 20px
    @media (min-width: 1024px)
      img:first-child
        width: 35%
      img:last-child
        width: 50%
    @media (min-width: 1280px)
      width: 75%
    @media (min-width: 1600px)
      width: 65%