section.video
  background: #f2f2f2
  padding: 2rem 0
  margin-bottom: 10%
  @media (min-width: 1024px)
    margin-bottom: 6%
  @media (min-width: 1280px)
    margin-bottom: 5%
  @media (min-width: 1600px)
    padding: 4rem 0
  .container
    gap: 1.5rem 0
    @media (min-width: 768px)
      flex-direction: row-reverse
      align-items: center
      justify-content: space-between
      gap: 0 5%
    @media (min-width: 1280px)
      width: 80%
    @media (min-width: 1440px)
      width: 75%
    @media (min-width: 1900px)
      width: 65%
    .text
      align-items: center
      h2, p
        text-align: center
        font-size: 24px
      h2
        color: $mainColor
      p
        font-weight: 700
        // font-size: 20px
      a
        border-radius: 20px
        padding: 8px 15px
        background: $mainColor
        color: $white
        margin-top: 1rem
        @media (min-width: 1280px)
          transition: .3s
          &:hover
            transform: scale(1.05)
      @media (min-width: 1280px)
        width: 35%
        p
          font-size: 22px
        h2
          font-size: 26px
      @media (min-width: 1280px)
        p
          font-size: 26px
        h2
          font-size: 30px
      .contact_click
        &:hover
          cursor: pointer
    .youtubeVideo
      width: 100%
      @media (min-width: 768px)
        width: 75%
      @media (min-width: 1024px)
        width: 55%
      iframe
        width: 100%
        border-radius: 15px
        aspect-ratio: 16/9